//统一管理api
import { base_bome } from "./base"; // 导入接口域名列表
import { post, _post } from "./tool"; // 导入http中创建的axios实例

export const getNewGameInfo = (params) => post(base_bome + "/newgame/info", params);
export const getNewGameList = (params) => post(base_bome + "/newgame/list", params);
export const getNewGameStorageCred = (params) => post(base_bome + "/newgame/storage/cred", params);
export const getNewGameSplTokenAssociated = (params) => post(base_bome + "/newgame/spltoken/associated", params);
export const getNewGameListBurn = (params) => post(base_bome + "/newgame/list/burn", params);
// 登录
export const getNewGameSignin = (params) => post(base_bome + "/newgame/signin", params);

//详情
export const getNewGameListDetail = (params) => post(base_bome + "/newgame/coin/show", params);
//添加评论
export const addComment = (params) => _post(base_bome + "/newgame/coin/add_comment", params);
//评论列表
export const commentList = (params) => post(base_bome + "/newgame/coin/comment_list", params);
//用户信息
export const getUserInfo = (params) => post(base_bome + "/newgame/user/user_info", params);
//设置用户信息
export const setUserInfo = (params) => _post(base_bome + "/newgame/user/set_user_info", params);
//TOKEN HELD
export const getTokenHeld = (params) => post(base_bome + "/newgame/coin/token_held", params);
//更新单条数据
export const getOneTokenHeld = (params) => post(base_bome + "/newgame/coin/token_held/one", params);
//TOKEN CREATED
export const getCreated = (params) => post(base_bome + "/newgame/coin/token_created", params);
//txs list
export const getTxsList = (params) => post(base_bome + "/newgame/coin/txs", params);
//获取safe_token
export const getSafeToken = (params) => _post(base_bome + "/newgame/user/safe_token", params);
//解绑twitter
export const unlockTwitter = (params) => _post(base_bome + "/newgame/auth/twitter/two/unbind", params);
// 列表查询mint信息
export const getQueryCoins = (params) => post(base_bome + "/newgame/coin/query_coins", params);
// header notify
export const getCoinNotify = (params) => post(base_bome + "/newgame/coin/notify", params);
//获取创建列表
export const getCreateList = (params) => post(base_bome + "/newgame/coin/list/test", params);
//获取奖券列表
export const getCouponList = (params) => _post(base_bome + "/newgame/coin/user/lotter_tickets", params);
//根据地址查询用户信息
export const getUserInfoOther = (params) => post(base_bome + "/newgame/user/system_user", params);
//查询mint的详情
export const getMintAdvPayer = (params) => post(base_bome + "/newgame/coin/query_sponsor", params);
//获取奖券开奖时间
export const getCouponTime = (params) => post(base_bome + "/newgame/coin/query_lottery_config", params);
//获取钱包接口sol
export const getBallanceValue = (params) => _post(base_bome + "/newgame/user/asset/coin", params);
//开奖列表资产变动
export const getCouponBallanceList = (params) => _post(base_bome + "/newgame/user/asset/events", params);
//添加个人新的list列表
export const getPersonList = (params) => _post(base_bome + "/newgame/coin/token_Listed", params);
//查询jackpot奖励
export const getJackpot = (params) => _post(base_bome + "/newgame/coin/query_trades", params);
// 创建查询交易状态
export const getCoinQuerySignature = (params) => post(base_bome + "/newgame/coin/query_signature", params);
//meme100list
export const getMeme100List = (params) => post(base_bome + "/newgame/activity/meme", params);
//meme100list
export const getIsTwitter = (params) => post(base_bome + "/newgame/query_twitter_users", params);
//获取币的版本v1还是v2
export const getIsV1OrV2 = (params) => post(base_bome + "/newgame/coin/query_coin_version", params);
//获取开宝箱配置
export const getOpenBoxConfig = (params) => _post(base_bome + "/newgame/activity/open_box/info", params);
//获取开宝箱配置
export const getOpenBoxStatus = (params) => _post(base_bome + "/newgame/activity/open_box/query_signature", params);
//查询发射是否成功
export const getPermitStatus = (params) => post(base_bome + "/newgame/coin/query_permit_signature", params);
//查询提现是否成功
export const getwithDrawStatus = (params) => _post(base_bome + "/newgame/activity/wd/query_signature", params);
//查询提现配置接口
export const getwithDrawConfig = (params) => _post(base_bome + "/newgame/activity/wd/info", params);
//swap配置
export const getSwapConfig = (params) => _post(base_bome + "/newgame/activity/exchange/info", params);
//查询swap是否成功
export const getSwapStatus = (params) => _post(base_bome + "/newgame/activity/exchange/query_signature", params);
//查询swap是否成功
export const getHistory = (params) => _post(base_bome + "/newgame/reward/emit/pending", params);
//邀请详情
export const getInviteInfo = (params) => _post(base_bome + "/newgame/invite/info", params);
//设置邀请码
export const setInviteCode = (params) => _post(base_bome + "/newgame/invite/set", params);
//查询K线数据
export const getChartsData = (params) => post(base_bome + "/newgame/chart/chart_data", params);

// 2.1版本新合约
// 高级创建配置
export const getChainAssetConfig = (params) => post(base_bome + "/newgame/query/chain_asset_config", params);
// board使用的query_users
export const getQueryUsers = (params) => post(base_bome + "/newgame/query_users", params);
// 在game合约内创建的mint
export const getQueryCoinList = (params) => post(base_bome + "/newgame/query_coin_list", params);
// 交易大赛
export const getTradingRoundsList = (params) => post(base_bome + "/newgame/activity/trading/rounds_list", params); //rounds_list
export const getTradingRoundsInfo = (params) => post(base_bome + "/newgame/activity/trading/rounds_info", params); //rounds_list
export const getTradingRankingList = (params) => post(base_bome + "/newgame/activity/trading/ranking_list", params); //rounds_list
export const getTradingRoundsRankingInfo = (params) => post(base_bome + "/newgame/activity/trading/rounds_ranking_info", params); //rounds_list
//v2fomo 3D交易大赛
export const getTradingRoundsInfoV2 = (params) => post(base_bome + "/newgame/activity/trading/fomo_rounds_info", params); //rounds_list
export const getRewardHistory = (params) => post(base_bome + "/newgame/activity/trading/fomo_reawar_history", params); //rounds_list

export default {
    getNewGameInfo,
    getNewGameList,
    getNewGameStorageCred,
    getNewGameSplTokenAssociated,
    getNewGameListBurn,
    getNewGameListDetail,
    addComment,
    commentList,
    getUserInfo,
    setUserInfo,
    getTokenHeld,
    getCreated,
    getOneTokenHeld,
    getTxsList,
    getSafeToken,
    unlockTwitter,
    getNewGameSignin,
    getQueryCoins,
    getCoinNotify,
    getCreateList,
    getCouponList,
    getUserInfoOther,
    getMintAdvPayer,
    getCouponTime,
    getBallanceValue,
    getCouponBallanceList,
    getPersonList,
    getJackpot,
    getCoinQuerySignature,
    getMeme100List,
    getIsTwitter,
    getChainAssetConfig,
    getIsV1OrV2,
    getOpenBoxConfig,
    getOpenBoxStatus,
    getPermitStatus,
    getQueryUsers,
    getwithDrawConfig,
    getwithDrawStatus,
    getQueryCoinList,
    getSwapConfig,
    getSwapStatus,
    getHistory,
    getTradingRoundsList,
    getTradingRoundsInfo,
    getTradingRankingList,
    getTradingRoundsRankingInfo,
    getInviteInfo,
    setInviteCode,
    getChartsData,
    getTradingRoundsInfoV2,
    getRewardHistory,
};
